import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm_language from "src/components/trbm_language"
import Chromedisclosureint from "src/components/chromedisclosureint"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import NonSearchDisclosure from "src/components/nonsearchdisclosure"

const deData = import("./data/data.json");

const deBrowsers = ['other','ie','firefox','chrome','edge'];

let css;
  if(isChrome()){
    css = ` `;
  }
  else{
    css = `
    `;
  }


export default function prdbest() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <Chromedisclosureint data={deData} language='it'></Chromedisclosureint>;
  }
  else{
    disclosure = <Chromedisclosureint data={deData} language='it'></Chromedisclosureint>;
  }
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/laptop-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }


    .footerlinks-module--br, .footerlinks-module--br a {
      color: #f1f1f1;
    }

    .trbm-module--trbm .trbm-module--bullets {
       padding: 0 0 0 25px;
       width: 80%;
     }
     .trbm_cta {
       padding: 15px 100px;
       border: none;
       font-size: 35px;
       color: #fff;
       font-weight: bold;
       background: #43ba19;
       border-radius: 10px;
       cursor: pointer;
       transition: .2s ease;
   }

   .trbm-module--trbm .trbm-module--step1 {
   left: 15px;
   }

   .trbm-module--trbm .trbm-module--step2 {
   left: 139px;
   font-size: 12px!important;
   }
    `}
    </style>

    <style type="text/css">
      {css}
    </style>

    <title>Free PDF Editor - pdftab.com</title></Helmet>
    <section>
      <Trbm_language data={deData} browsers={deBrowsers} language='it'>
        {disclosure}
      </Trbm_language >
      </section>
    </HomepageLayout>
  )
}
