import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/chromedisclosureint.module.scss"
class Chromedisclosureint extends React.Component {
    constructor(props){
        super(props);
        this.state = ({data: {},language: {}});
    }
    componentDidMount(){
         this.props.data.then(data => {
             this.setState({data: data});
            this.setState({language: this.props.language});
        });
    }
  render() {
    return (
        <div className = {Styles.chromedisclosureint}>
          {(() => {
            switch (this.state.language) {
              case 'de':
                return   <p className={Styles.disc}>Diese kostenlose Extension aktualisiert die Suchmaschine dieses Browsers auf eine von Yahoo! betriebene. Wenn Sie fortführen, erkennen Sie die <a href="https://clearbar.app/terms-of-service/" target="_blank" className={Styles.bold} >Nutzungsbedingungen</a> und die <a href="https://clearbar.app/privacy-policy/" target="_blank" className={Styles.bold} >Datenschutzrichtlinie</a>, an und akzeptieren, dass Ihnen möglicherweise weitere optionale Angebote unterbreitet werden.</p>;
                break;

                case 'fr':
                  return   <p className={Styles.disc}>Cette extension gratuite mettra à jour le moteur de recherche de ce navigateur pour qu'il soit alimenté par Yahoo ! En continuant, vous reconnaissez les <a href="https://clearbar.app/terms-of-service/" target="_blank" className={Styles.bold} >Conditions de Service</a> et la <a href="https://clearbar.app/privacy-policy/" target="_blank" className={Styles.bold} >Politique de Confidentialité</a>, et vous acceptez que d'autres offres facultatives puissent vous être présentées.</p>;
                  break;


                  case 'it':
                    return   <p className={Styles.disc}>Questa estensione gratuita aggiornerà il motore di ricerca di questo browser con uno gestito da Yahoo!. Continuando, riconosci i 
                  
                    <a href="https://clearbar.app/terms-of-service/" target="_blank" className={Styles.bold} >Termini di servizio</a> 
                    
                    und der
                    
                    <a href="https://clearbar.app/privacy-policy/" target="_blank" className={Styles.bold} >l'Informativa sulla privacy</a> 
                    
                    e che ti potrebbero essere presentate ulteriori offerte facoltative.</p>;
                    break;

              default:
                return   <p className={Styles.disc}>This free extension will update this browser's search engine to one powered by Yahoo!. By continuing, you acknowledge the <a href="https://clearbar.app/terms-of-service/" target="_blank" className={Styles.bold} >Terms</a> and <a href="https://clearbar.app/privacy-policy/" target="_blank" className={Styles.bold} >Privacy Policy</a>, and that you may be presented with more optional offers.</p>;
            }
          })()}
        </div>
    )
  }
}
export default Chromedisclosureint
